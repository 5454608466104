import store from '../store'

export interface PermissionsInterface {
  name: string;
  code: string;
  internal: string[];
}

export const PermissionsList: PermissionsInterface[] = [
  {
    name: 'Crear cuenta empresa',
    code: '0-1',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Crear responsables en cuenta empresa',
    code: '0-2',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Actualizar cuenta empresa',
    code: '0-3',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Eliminar cuenta empresa',
    code: '0-4',
    internal: [
      'admin',
    ],
  },
  {
    name: 'Visualizar cuenta empresa',
    code: '0-5',
    internal: [
      'admin',
    ],
  },
  // Permisologia Gap Analisis
  {
    name: 'Create Gap Analisis',
    code: '1-1',
    internal: [
      'admin', 'implementer',
    ],
  },
  {
    name: 'Read Gap Analisis',
    code: '1-2',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Update Gap Analisis',
    code: '1-3',
    internal: [
      'admin',
      'implementer',
    ],
  },
  {
    name: 'Destroy Gap Analisis',
    code: '1-4',
    internal: [
      'admin',
      'implementer',
    ],
  },
  {
    name: 'Show Gap Analisis',
    code: '1-5',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  // Publicar Avance (SnapShot)
  {
    name: 'Create Snapshot',
    code: '2-1',
    internal: [
      'admin', 'implementer',
    ],
  },
  {
    name: 'Read Snapshot',
    code: '2-2',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Update Snapshot',
    code: '2-3',
    internal: [
      'admin',
      'implementer',
    ],
  },
  {
    name: 'Destroy Snapshot',
    code: '2-4',
    internal: [
      'admin',
      'implementer',
    ],
  },
  {
    name: 'Show Snapshot',
    code: '2-5',
    internal: [
      'admin',
      'implementer',
      'customer_success',
    ],
  },
  {
    name: 'Index Snapshot',
    code: '2-5',
    internal: [
      'admin',
    ],
  },
  {
    name: 'Index Create Snapshot',
    code: '2-6',
    internal: [
      'admin',
      'implementer',
    ],
  },
  {
    name: 'Index Templates',
    code: '3-0',
    internal: [
      'admin',
      'implementer',
    ],
  },
]


export const VerificationPermission = (need?: string) => {
  const role = store.getters['AuthModule/getUserRole'].role
  let can = false
  PermissionsList.forEach(element => {
    if (element.code === need) {
      can = element.internal.includes(role)
    }
  })

  return can
}