
import { Component, Vue, Watch } from "vue-property-decorator";
import { mapState, mapGetters } from "vuex";
import OverlayBackground from "@/components/OverlayBackground/Index.vue";

import { BusinessInterface } from "@/store/types/Business/Business";

import ConfirmDialog from "@/components/ConfirmDialog/Index.vue";
import TableEmptyState from "@/components/TableEmptyState/Index.vue";
import { VerificationPermission } from "@/utils/Permissions";
import { TemplateGetInterface } from "@/store/modules/recomendations/recomendationModule";

@Component({
  components: {
    ConfirmDialog,
    TableEmptyState,
    OverlayBackground,
  },
  computed: {
    ...mapState("BusinessModule", ["BusinessState"]),
    ...mapGetters("BusinessModule", ["getBusinessPages"]),
    ...mapGetters("BusinessModule", ["getBusinessList"]),
    canCreateBusiness() {
      return VerificationPermission("0-1");
    },
    canDeleteBusiness() {
      return VerificationPermission("0-4");
    },
  },
  head: {
    script: [{ type: "application/javascript", src: "../js/analytics.js" }],
  },
})
export default class ClientsComponent extends Vue {
  private confirm = false;
  private business = [];
  private selected = [];
  private auxId = 35;
  private Busi = this.$store.state.BusinessModule.BusinessState;
  private page = 1;
  private searchText = "";
  private loading = false;
  private ADMIN_ROLES = process.env.VUE_APP_PRIVILEGED_ROLES?.split(",") || [];
  mounted(): void {
    this.$mixpanel.track("Seccion Empresas");
    this.getBusiness();
    this.getResources();
  }

  private getResources() {
    this.loading = true;
    this.$store
      .dispatch("PolimorficModule/getResources")
      .then(() => {
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }
  goToClientProfile(element: BusinessInterface) {
    this.$store.commit("BusinessModule/SET_BUSINESS_SELECTED", element);
    const clientProfile = `/startup/activity?companyId=${element.id}`;
    const business = this.$store.state.BusinessModule.BusinessState.business.find(
      (business: BusinessInterface) => business.id === element.id
    );
    this.$store.commit("AuthModule/SET_COMPANY_NAME_SELECTED", business.name);
    this.$store.commit("AuthModule/SET_COMPANY_FRAMEWORKS_SELECTED", business.frameworks);
    this.$store.commit("AuthModule/SET_COMPANY_FOLDER_ID_SELECTED", business.folder.id);
    this.$router.push(clientProfile);
  }

  goToBusiness(element: BusinessInterface) {
    this.$store.commit("BusinessModule/SET_BUSINESS_SELECTED", element);
    this.$store.commit("PolimorficModule/SET_FRAMEWORK_SELECTED", element.frameworks[0]);
    this.$mixpanel.track("Empresa Seleccionada", { Empresa: element.name });
    this.$router.push(`/app/clients/${element.id}`).catch((error) => {
      if (error.name != "NavigationDuplicated") return;
    });
  }

  editToBusiness(element: BusinessInterface) {
    this.$store.commit("BusinessModule/SET_BUSINESS_SELECTED", element);
    this.$router.push(`/app/clients/${element.id}/filebusiness`);
  }

  private getBusiness() {
    this.$store.commit("GapModule/SET_VERSION_CATEGORIES", 0);
    this.loading = true;

    const sendTemplatesInterface: TemplateGetInterface = {
      page: this.page,
      name_cont: this.searchText,
    };

    this.$store
      .dispatch("BusinessModule/getBusiness", sendTemplatesInterface)
      .then(() => {
        this.business = this.Busi.business;
        this.$store.commit("SearchModule/SET_SEARCH_RESULTS", this.Busi.business);
        this.loading = false;
      })
      .catch(() => {
        this.loading = false;
      });
  }

  @Watch("page")
  private getOtherPage() {
    this.getBusiness();
  }
}
